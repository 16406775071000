export default class Pagination {

    constructor($container) {
      let it = this;
  
      it.$pagination = $container;
      it.$pages_container = it.$pagination.find('.pagination__pages');
      it.$prev_arrow = it.$pagination.find('.pagination__prev');
      it.$next_arrow = it.$pagination.find('.pagination__next');
  
      it.pages_count = null;
      it.current_page = null;
  
      it.pages_before_current = IS_MOBILE ? 2 : 5;   // Отображаемые страницы до текущей
      it.pages_after_current = IS_MOBILE ? 2 : 5;    // Отображаемые страницы после текущей
      it.pages_after_dots = 2;       // Количество страниц после многоточия
      it.min_pages_under_dots = 3;   // Минимум "не влезших" страниц, при котором добавится многоточие
  
      it.bindEvents();
    }
  
  
    bindEvents() {
      let it = this;
  
      it.$pagination.on('click', '.pagination__page', function(event) {
          it.current_page = Number(event.target.closest('.pagination__page').getAttribute('data-page'));
          it.triggerChange(it.current_page);
      });
  
      it.$prev_arrow.on('click', function() {
        if(it.current_page > 1) {
          it.current_page--;
          it.createPagination();
          it.triggerChange(it.current_page)
        }
      })
  
      it.$next_arrow.on('click', function() {
        if(it.current_page < it.pages_count) {
          it.current_page++;
          it.createPagination();
          it.triggerChange(it.current_page)
        }
      })
    }
  
  
    setPagesCount(count) {
      let it = this;
      it.pages_count = +count;
      it.current_page = 1;
  
      if(count === 1) {
        it.$pagination.addClass('_hidden');
      } else {
        it.$pagination.removeClass('_hidden');
      }
    }
  
  
    setCurrentPage(index) {
      let it = this;
      it.current_page = +index;
      it.createPagination();
    }
  
  
    triggerChange(current_page_index) {
      let it = this;
      it.$pagination.trigger('page-change', {
        page: current_page_index
      });
    }
  
  
    createPagination() {
      let it = this;
  
      it.$pagination.find('.pagination__page').remove();
      it.$pagination.find('.pagination__dots').remove();
  
      // Создаём ссылки на текущую страницу и страницы до и после неё
      let start_index = it.current_page - it.pages_before_current;
      let end_index = it.current_page + it.pages_after_current;
  
      if(start_index < 1) {
        start_index = 1;
      }
  
      if(end_index > it.pages_count) {
        end_index = it.pages_count;
      }
  
      it.createPaginationLine(start_index, end_index, it.current_page);
  
      const remaining_pages_count = it.pages_count - end_index;
      // Если страниц осталось много, скрываем часть под многоточие
      if(remaining_pages_count >= it.min_pages_under_dots + it.pages_after_dots) {
        it.$pages_container.append(`<button class="pagination__dots">...</button>`);
        start_index = it.pages_count - (it.pages_after_dots - 1);
        end_index = it.pages_count;
        it.createPaginationLine(start_index, end_index);
      // Если страниц осталось немного, просто добавляем их в список
      } else {
        start_index = end_index + 1;
        end_index = it.pages_count;
        it.createPaginationLine(start_index, end_index);
      }
    }
  
  
    createPaginationLine(start_index, end_index) {
      let it = this;
  
      for(var page_index = start_index; page_index <= end_index; page_index++) {
        let extra_class = '';
  
        if(it.current_page == page_index) {
          extra_class = '_active';
        }
        it.$pagination.removeClass('_no-prev _no-next');
        if(it.current_page == 1) {
          it.$pagination.addClass('_no-prev');
        }
        if(it.current_page == end_index) {
          it.$pagination.addClass('_no-next');
        }
        const button_html = `<button class="pagination__page ${extra_class}" data-page="${page_index}">${page_index}</button>`;
        it.$pages_container.append(button_html);
      }
    }
  }
  


// export default class Pagination {

//     constructor($container) {
//         let it = this;

//         it.$container = $container;
//         it.$pages_container = $container.find('.pagination__pages');
//         it.$pagination_buttons = null;
//         it.$prev_btn = $container.find('.pagination__prev');
//         it.$next_btn = $container.find('.pagination__next');

//         it.page = 1;
//         it.pages = null;
        
//         it.bindEvents();
//     }


//     bindEvents() {
//         let it = this;

//         it.$pages_container.on('click', 'button', function(event) {
//             const page = this.getAttribute('data-page');
//             it.setActivePage(page);
//             it.$container.trigger('page-change', {
//                 page: page,
//             })
//         });

//         it.$prev_btn.on('click', function() {
//             const new_page = --it.page;
//             if(new_page > 0) {
//                 it.setActivePage(new_page);
//             }
//         })

//         it.$next_btn.on('click', function() {
//             const new_page = ++it.page;
//             if(it.$pagination_buttons && new_page <= it.$pagination_buttons.length) {
//                 it.setActivePage(new_page);
//             }
//         });
        
//     }


//     render(pages, page=1) {
//         let it = this;

//         it.pages = pages;
//         it.page = page;

//         it.$pages_container.empty();
//         for(var p=1; p<=pages; p++) {
//             it.$pages_container.append(it.createButtonHtml(p));
//         }
        
//         it.$pagination_buttons = it.$pages_container.find('button');
//         it.setActivePage(page);
//         it.$container.addClass('_loaded');
//     }


//     setActivePage(index) {
//         let it = this;

//         it.page = index;

//         const $btn = it.$pagination_buttons.filter(function(index) {
//             return this.getAttribute('data-page') == it.page;
//         });

//         if($btn.length) {
//             it.$pages_container.find('button').removeClass('_active');
//             $btn.addClass('_active');

//             it.$container.trigger('page-change', {
//                 page: it.page
//             })
//         }
//     }


//     createButtonHtml(index) {
//         let it = this;

//         return `<button class="pagination__page" data-page="${index}">${index}</button>`;
//     }
// }