export default class PriceLists {

    constructor() {
        let it = this;

        let localization_ru = {
            distribution_agreement: 'Агентский договор',
            main_agreement: 'Договор поставки',
            price_without_vat: 'Цена без НДС',
            price_including_vat: 'Цена с НДС',
            rub: 'Р'
        }

        let localization_en = {
            distribution_agreement: 'Distribution Agreement',
            main_agreement: 'The main contract',
            price_without_vat: 'Price without VAT',
            price_including_vat: 'Price including VAT',
            rub: 'RUB'
        }

        it.localization = {};

        switch(SITE_LANGUAGE) {
            case 'RU':
                it.localization = localization_ru;
                break;
            case 'EN':
                it.localization = localization_en;
                break;
            default:
                it.localization = localization_ru;
                break;
        }

        it.bindEvents();
    }


    bindEvents() {
        let it = this;

        $(document).on('click', '.price-list__table-header', function(event) {
            if(event.target.closest('a')) return;

            const   $table = $(this).closest('.price-list__table'),
                    $table_content = $table.find('.price-list__table-items');

            if(!$table.hasClass('_opened')) {
                $table.addClass('_opened');
                $table_content.slideDown(600);
            } else {
                $table.removeClass('_opened');
                $table_content.slideUp(600);
            }
        })
    }


    getBasises(product_type_id) {
        let it = this;

        let url = '/api/delivery-bases';
        if(product_type_id) {
            url += `?product_type=${product_type_id}`;
        }

        return new Promise((resolve) => {
            $.ajax({
                headers: {
                    'X-localization': SITE_LANGUAGE.toLowerCase(),
                },
                url: url,
                type: 'GET',
                complete: (data) => {
                    console.log(data)
                    if(data.responseJSON.data) {
                        it.basises = data.responseJSON.data;
                        resolve(data.responseJSON);
                    }
                }
            })
        })
    }


    getProducts() {
        let it = this;

        return new Promise((resolve) => {
            $.ajax({
                headers: {
                    'X-localization': SITE_LANGUAGE.toLowerCase(),
                },
                url: `/api/products`,
                type: 'GET',
                complete: (data) => {
                    if(data.responseJSON.data) {
                        it.products= data.responseJSON.data;
                        resolve(data.responseJSON);
                    }
                }
            })
        })
    }


    getBasisById(basis_id) {
        let it = this;

        if(!basis_id.length) {
            return it.basises;
        } else {
            return it.basises.filter((basis) => {
                return basis.id == basis_id;
            })
        }
    }

    getProductTypeById(product_id) {
        let it = this;

        let id = null;
        it.products.forEach((product) => {
            if(product.id == product_id) {
                id = product.relationships.product_type.id;
            }
        })

        return id;
    }


    filterProductsByType(products, type_id) {
        let it = this;

        return products.filter((product) => {
            return product.relationships.product_type.id == type_id;
        })
    }


    createBasisHtml(item, product_type_id) {
        let it = this;

        let html = 
        `
        <div class="price-list__table">
            <!-- Шапка таблцицы -->
            <div class="price-list__table-header">
                <div class="price-list__table-title">
                    <h5>${item.attributes.title}</h5>
                    <p class="text-2">${item.attributes.address}</p>
                </div>
                <div class="price-list__documents">
                    <a class="price-list__document-link" href=${item.attributes.agent_agreement} target="_blank">
                        <svg>
                            <use xlink:href="#icon-file"></use>
                        </svg>
                        <span>${it.localization.distribution_agreement}</span>
                    </a>
                    <a class="price-list__document-link" href=${item.attributes.main_agreement} target="_blank">
                        <svg>
                            <use xlink:href="#icon-file"></use>
                        </svg>
                        <span>${it.localization.main_agreement}</span>
                    </a>
                </div>
                <button class="price-list__table-trigger-btn">
                    <svg>
                        <use xlink:href="#icon-direction-down"></use>
                    </svg>
                </button>
            </div>
            <!-- Внутренние плашки -->
            <div class="price-list__table-items">

        `

        item.relationships.products.forEach((product) => {
            const price = (typeof(product.attributes.price) === 'string') 
                ? product.attributes.price
                : `${Number(product.attributes.price).toFixed(2)} ${it.localization.rub}`;
            if(!product_type_id || it.getProductTypeById(product.id) == product_type_id) {
                html += 
                    `
                    <div class="price-list__item">
                        <div class="price-list__item-left">
                            <h6>${product.attributes.title}</h6>
                            <div class="price-list__item-description text-3">${product.attributes.description}</div>
                        </div>
                        <div class="price-list__item-price _green">
                            <p class="text-4">${it.localization.price_without_vat}</p>
                            <span>${price}</span>
                        </div>
                        <div class="price-list__item-price">
                            <p class="text-4">${it.localization.price_including_vat}</p>
                            <span>+${product.attributes.price_vat}%</span>
                        </div>
                    </div>
                    `
            }
        })

        html += `</div></div>`;

        return html;
    }

    // Эта функция группирует продукцию с одинаковым классом, чтобы можно было всю её вывести под одной плашкой "Пшеница 3 класса" и т.п
    groupProductsByClass(items) {
        const unique_combinations = []; // type_id + class_id
        const result = [];
        items.forEach((item, index) => {
            const type_id = item.relationships.product_type.id;
            const class_id = item.relationships.product_class.id || '';

            if(!class_id) {
                result.push({
                    ...item,
                    class_products: []
                });
            } else {
                const combination_id = String(type_id) + String(class_id);

                if(unique_combinations.indexOf(combination_id) === -1) {
                    unique_combinations.push(combination_id);
                    result.push({
                        combination_id: combination_id,
                        ...item,
                        class_products: []
                    });
                } else {
                    result.filter((item) => {
                        return item.combination_id === combination_id;
                    })[0].class_products.push(item);
                }
            }
        })
        console.log(result)
        return result;
    }


    createProductHtml(item) {
        let it = this;

        let offers = item.relationships.delivery_bases.data.length;

        item.class_products.forEach((class_item) => {
            offers += class_item.relationships.delivery_bases.data.length;
        });

        let positions_string = '';

        switch(SITE_LANGUAGE) {
            case 'RU':
                positions_string = `Всего ${offers} ${getWordDeсlension(offers, 'позиция', 'позиции', 'позиций')}`;
                break;
            case 'EN':
                if(offers == 1) {
                    positions_string = `1 item total`;
                } else {
                    positions_string = `${offers} items total`;
                }
                break;
            default:
                positions_string = `Всего ${offers} ${getWordDeсlension(offers, 'позиция', 'позиции', 'позиций')}`;
        }

        let html = `
            <div class="price-list__table _product">
                <!-- Шапка таблцицы -->
                <div class="price-list__table-header">
                    <div class="price-list__table-title">
                        <h5>${item.attributes.title}</h5>
                    </div>
                    <div class="price-list__positions-count text-3">
                        ${positions_string}
                    </div>
                    <button class="price-list__table-trigger-btn" aria-label="Показать сведения о продукте">
                        <svg>
                            <use xlink:href="#icon-direction-down"></use>
                        </svg>
                    </button>
                </div>
                <!-- Внутренние плашки -->
                <div class="price-list__table-items">
                    
        `;

        item.relationships.delivery_bases.data.forEach((base) => {
            html += `
                <div class="price-list__item">
                    <div class="price-list__item-left">
                        <h6>${base.attributes.delivery_base[0].title}</h6>
                        <div class="price-list__item-description text-3">${item.attributes.description}</div>
                    </div>
                    <div class="price-list__item-price _green">
                        <p class="text-4">${it.localization.price_without_vat}</p>
                        <span>${Number(base.attributes.price).toFixed(2)} ${it.localization.rub}</span>
                    </div>
                    <div class="price-list__item-price">
                        <p class="text-4">${it.localization.price_including_vat}</p>
                        <span>+${base.attributes.price_vat}%</span>
                    </div>
                </div>
            `
        })

        item.class_products.forEach((class_item) => {
            class_item.relationships.delivery_bases.data.forEach((base) => {
                html += `
                    <div class="price-list__item">
                        <div class="price-list__item-left">
                            <h6>${base.attributes.delivery_base[0].title}</h6>
                            <div class="price-list__item-description text-3">${class_item.attributes.description}</div>
                        </div>
                        <div class="price-list__item-price _green">
                            <p class="text-4">${it.localization.price_without_vat}</p>
                            <span>${Number(base.attributes.price).toFixed(2)} ${it.localization.rub}</span>
                        </div>
                        <div class="price-list__item-price">
                            <p class="text-4">${it.localization.price_including_vat}</p>
                            <span>+${base.attributes.price_vat}%</span>
                        </div>
                    </div>
                `
            })
        })

        html += `</div></div>`;

        return html;
    }
}