import { tablet_breakpoint, mobile_breakpoint } from './constants';

export default class Helpers {

  constructor() {
    window.breakpoint = null;
    window.prev_breakpoint = null;
    window.IS_MOBILE = $('html').hasClass('desktop') ? false : false;

    this.setScreenBreakpoint();
    this.helpers();
    this.bindEvents();
  }

  helpers() {
    // Полифилл element.closest для IE 11 
    (function(ELEMENT) {
        ELEMENT.matches = ELEMENT.matches || ELEMENT.mozMatchesSelector || ELEMENT.msMatchesSelector || ELEMENT.oMatchesSelector || ELEMENT.webkitMatchesSelector;
        ELEMENT.closest = ELEMENT.closest || function closest(selector) {
            if (!this) return null;
            if (this.matches(selector)) return this;
            if (!this.parentElement) {return null}
            else return this.parentElement.closest(selector)
          };
    }(Element.prototype));

    // Полифилл isInteger для IE 11 
    Number.isInteger = Number.isInteger || function(value) {
      return typeof value === "number" && 
             isFinite(value) && 
             Math.floor(value) === value;
    };

    // Запрет перетаскивания img
    $(document).on('dragstart', 'img', function(event) { 
      event.preventDefault();
      return false;
    });

    // Проверка на IE
    Modernizr.addTest('ie', function () {
      var ua = window.navigator.userAgent;
      var msie = ua.indexOf('MSIE ') > 0;
      var ie11 = ua.indexOf('Trident/') > 0;
      var ie12 = ua.indexOf('Edge/') > 0;
      return msie || ie11 || ie12;
    });

    if(Modernizr.ie) {
      $("html").addClass("ie");
    }

    // Проверка на Safari
    const is_safari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);
    if(is_safari == true) {
      $("html").addClass("safari");
    }

    $.fn.hasExtension = function(exts) {
      return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test($(this).val());
    }

    window.pad = this.pad;
    window.getRandomInt = this.getRandomInt;
    window.setCookie = this.setCookie;
    window.getCookie = this.getCookie;
    window.getUrlParam = this.getUrlParam;
    window.getWordDeсlension = this.getWordDeсlension;
  }

  // Случайное целое число
  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Добавить нужное количество нулей в начало строки
  pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
  }

  // var_1 - именительный, var_2 - родительный единственное число, var_3 - родительный множественное число
  getWordDeсlension(number, var_1, var_2, var_3) {
    if(number > 10 && number < 20) {
      return var_3;
    } else {
      const number_last_digit = +(String(number).split("").splice(-1, 1));
      switch(number_last_digit) {
        case 0: case 5: case 6: case 7: case 8: case 9:
          return var_3;
        case 1:
          return var_1;
        case 2: case 3: case 4:
          return var_2;
      }
    }
  }

  getUrlParam(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
  };

  setCookie(c_name, value, exdays) {
    var exdate = new Date();
      exdate.setDate(exdate.getDate() + exdays);
      var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
      document.cookie = c_name + "=" + c_value;
  }

  getCookie(c_name) {
      var i, x, y, ARRcookies = document.cookie.split(";");
      for (i = 0; i < ARRcookies.length; i++) {
          x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
          y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
          x = x.replace(/^\s+|\s+$/g, "");
          if (x == c_name) {
              return unescape(y);
          }
      }
  }


  bindEvents() {
    $(window).on("resize", this.setScreenBreakpoint.bind(this));
    $(window).on("breakpointChange", this.ajustPageToBreakpoint.bind(this))
  }

  setScreenBreakpoint() {
    const width = $(window).width();
    window.prev_breakpoint = window.breakpoint;

    if(width > tablet_breakpoint) {
      window.breakpoint = "desktop";
    } else 
    if(width <= mobile_breakpoint) {
      window.breakpoint = "mobile";
    } else {
      window.breakpoint = "tablet";
    }

    if(window.prev_breakpoint && window.breakpoint !== window.prev_breakpoint) {
      $(window).trigger("breakpointChange");
    }
  }

  ajustPageToBreakpoint() {
    // console.log("breakpoint changed from " + window.prev_breakpoint + " to " + window.breakpoint);
  }
}
