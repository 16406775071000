import { mobile_breakpoint } from '../constants';

import PartnersList from '../components/partners-list';
import NewsList from '../components/news-list';
import ProductsTypesList from '../components/products-types-list';

export default class Mainpage {

    constructor() {
        let it = this;

        it.$products_slider = $('.mainpage__products-cards');
        it.$feedback_slider = $('.mainpage__feedback-cards');
        it.$news_slider = $('.mainpage__news-cards');

        it.partners_list = new PartnersList();
        it.news_list = new NewsList();
        it.products_types_list = new ProductsTypesList();

        it.bindEvents();
        it.getFeedbackCards();
        it.getNewsCards();
        it.getProductsCards();
    }


    bindEvents() {
        let it = this;

        // slick не умеет пересобираться сам после вызова unslick
        $(window).on('breakpointChange', function() {
            if(window.breakpoint === 'mobile') {
                it.initProductsSlider();
                it.initFeedbackSlider();
                it.initNewsSlider();
            }
        })

        it.$products_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            it.$products_slider.removeClass('_first-slide-active _last-slide-active');
            if(nextSlide === it.$products_cards.length - 1) {
                it.$products_slider.addClass('_last-slide-active');
            }
            if(nextSlide === 0) {
                it.$products_slider.addClass('_first-slide-active');
            }
        });

        it.$feedback_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            it.$feedback_slider.removeClass('_first-slide-active _last-slide-active');
            if(nextSlide === it.$feedback_cards.length - 1) {
                it.$feedback_slider.addClass('_last-slide-active');
            }
            if(nextSlide === 0) {
                it.$feedback_slider.addClass('_first-slide-active');
            }
        });
    }


    getFeedbackCards() {
        let it = this;

        it.partners_list.getPartners().then((data) => {
            for(let i=0; i<4; i++) {
                const card_data = data.data[i].attributes;
                it.$feedback_slider.append(
                    it.partners_list.createCardHtml(card_data)
                )
            }
            it.initFeedbackSlider();
        });
    }


    initProductsSlider() {
        let it = this;

        it.$products_cards = it.$products_slider.find('.product-card');

        it.$products_slider.slick({
            mobileFirst: true,
            arrows: false,
            infinite: false,
            variableWidth: true,
            centerMode: true,
            easing: 'linear',
            responsive: [
                {
                    breakpoint: mobile_breakpoint,
                    settings: 'unslick',
                }
            ]
        })

        it.$products_slider.slick('slickGoTo', 0)
    }


    initFeedbackSlider() {
        let it = this;

        it.$feedback_cards = it.$feedback_slider.find('.feedback-card');

        it.$feedback_slider.slick({
            mobileFirst: true,
            arrows: false,
            infinite: false,
            variableWidth: true,
            centerMode: true,
            easing: 'linear',
            responsive: [
                {
                    breakpoint: mobile_breakpoint,
                    settings: 'unslick',
                }
            ]
        })

        it.$feedback_slider.slick('slickGoTo', 0)

        it.$feedback_slider.on('setPosition', function () {
            $(this).find('.slick-slide').height('auto');
            var slickTrack = $(this).find('.slick-track');
            var slickTrackHeight = $(slickTrack).height();
            $(this).find('.slick-slide').css('height', slickTrackHeight + 'px');
        });
    }


    getNewsCards() {
        let it = this;

        it.news_list.getNews().then((data) => {
            const cards = it.news_list.sortCardsByDate(data.data);
            for(let i=0; i<3; i++) {
                if(!cards[i]) return '';

                let card_html;
                if(i === 0) {
                    card_html = it.news_list.createCardHtml(cards[i], '_large')
                } else {
                    card_html = it.news_list.createCardHtml(cards[i])
                }
                $('.mainpage__news-cards').append(card_html);
            }
            it.initNewsSlider();
        })
    }


    initNewsSlider() {
        let it = this;

        it.$news_slider.slick({
            mobileFirst: true,
            arrows: false,
            infinite: false,
            centerMode: true,
            variableWidth: true,
            easing: 'linear',
            responsive: [
                {
                    breakpoint: mobile_breakpoint,
                    settings: 'unslick',
                }
            ]
        })
    }


    getProductsCards() {
        let it = this;

        it.products_types_list.getProductsTypes(true).then((data) => {
            // console.log(data)
            data.data.forEach((item) => {
                $('.mainpage__products-cards').append(
                    it.products_types_list.createCardHtml(item)
                )
            })
            $('.mainpage__products').addClass('_loaded');
            it.initProductsSlider();
        })
    }
}