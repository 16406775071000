import { tablet_breakpoint, mobile_breakpoint } from '../constants';

import PartnersList from '../components/partners-list';

export default class AboutUs {

    constructor() {
        let it = this;

        it.$history_slider = $('.business__history-slider');
        it.$feedback_slider = $('.business__feedback-cards');

        it.partners_list = new PartnersList();

        it.getHistory();
        it.getFeedbackCards();
        it.bindEvents();
    }


    bindEvents() {
        let it = this;

        // slick не умеет пересобираться сам после вызова unslick
        $(window).on('breakpointChange', function() {
            if(window.breakpoint === 'mobile') {
                it.initFeedbackSlider();
            }
        })

        it.$feedback_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            it.$feedback_slider.removeClass('_first-slide-active _last-slide-active');
            if(nextSlide === it.$feedback_cards.length - 1) {
                it.$feedback_slider.addClass('_last-slide-active');
            }
            if(nextSlide === 0) {
                it.$feedback_slider.addClass('_first-slide-active');
            }
        });
    }


    getFeedbackCards() {
        let it = this;

        it.partners_list.getPartners().then((data) => {
            for(let i=0; i<4; i++) {
                const card_data = data.data[i].attributes;
                it.$feedback_slider.append(
                    it.partners_list.createCardHtml(card_data)
                )
            }
            it.initFeedbackSlider();
        });
    }


    initFeedbackSlider() {
        let it = this;

        it.$feedback_cards = it.$feedback_slider.find('.feedback-card'); 

        it.$feedback_slider.slick({
            mobileFirst: true,
            arrows: false,
            infinite: false,
            variableWidth: true,
            centerMode: true,
            easing: 'linear',
            responsive: [
                {
                    breakpoint: mobile_breakpoint,
                    settings: 'unslick',
                }
            ]
        })

        it.$feedback_slider.slick('slickGoTo', 0);

        it.$feedback_slider.on('setPosition', function () {
            $(this).find('.slick-slide').height('auto');
            var slickTrack = $(this).find('.slick-track');
            var slickTrackHeight = $(slickTrack).height();
            $(this).find('.slick-slide').css('height', slickTrackHeight + 'px');
        });
    }

    getHistory() {
        let it = this;
    
        $.ajax({
            headers: {
                'X-localization': SITE_LANGUAGE.toLowerCase(),
            },
            url: '/api/company-histories',
            type: 'GET',
            complete: (data) => {
                if(data.responseJSON.data) {
                    let historyData = data.responseJSON.data;
                    historyData.forEach((item, index) => {
                        const isLast = index === historyData.length - 1; // Проверка на последний элемент
                        it.$history_slider.append(it.createHistorySlideHtml(item, isLast))
                    })
                    it.initHistorySlider();
                }
            }
        })
    }
    

    createHistorySlideHtml(item, isLast) {
        let yearText = item.attributes.year;
        let currentLang = this.getCurrentLang();
        if (isLast) {
            yearText += currentLang === 'en' ? " and to the present" : " и по настоящее время";
        }
        return `
        <div class="business__history-slide">
            <div class="business__history-slide-year text-4">${yearText}</div>
            <div class="business__history-slide-text text-2">
                ${item.attributes.description}
            </div>
        </div>
        `;
    }
    
    getCurrentLang() {
        return $('html').attr('lang') || 'ru';
    }

    initHistorySlider() {
        let it = this;

        it.$history_slider.slick({
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: `
                <button class="business__history-slider-btn arrow-btn _prev">
                    <svg><use xlink:href="#icon-arrow-left-short"></svg>
                </button>`,
            nextArrow: `
                <button class="business__history-slider-btn arrow-btn _next">
                    <svg><use xlink:href="#icon-arrow-right-short"></svg>
                </button>`,
            responsive: [
                {
                    breakpoint: tablet_breakpoint,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: mobile_breakpoint,
                    settings: {
                        slidesToShow: 1,
                        variableWidth: true,
                        arrows: false
                    }
                },
            ]
        })
    }
}