export default class PartnersList {

    getPartners(page = 1) {
        return new Promise((resolve, reject) => {
            $.ajax({
                headers: {
                    'X-localization': SITE_LANGUAGE.toLowerCase(),
                },
                type: 'GET',
                url: `/api/reviews?page=${page}`,
                complete: (data) => {
                    if(data.status === 200) {
                        resolve(data.responseJSON)
                    } else {
                        reject('error')
                    }
                }
            })
        })
    }


    createCardHtml(card) {
        return `
            <div class="feedback-card">
                <div class="feedback-card__image">
                <img src='/storage/${card.logo}'></div>
                <div class="feedback-card__description">
                    <p class="feedback-card__title text-2-bold">${card.title}</p>
                    <div class="feedback-card__text text-2">${card.text}</div>
                </div>
            </div>
        `
    }
}