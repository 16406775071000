export default class Programs {

    constructor() {
        let it = this;

        it.init();
    }


    init() {
        let it = this;

        // $('.program__content ul').addClass('simple-list');

        $(document).on('click', '.program__header', function(event) {
            const   $program = $(this).closest('.program'),
                    $content = $program.find('.program__content');

            if(!$program.hasClass('_opened')) {
                $program.addClass('_opened');
                $content.slideDown(600);
            } else {
                $program.removeClass('_opened');
                $content.slideUp(600);
            }
        })
    }
}