import FeedbackForm from './feedback-form';
import IndustrySubscriptionForm from './industry-subscription-form';
import NewsSubscribeForm from './news-subscribe-form';

export default class Forms {

  constructor() {
    let it = this;

    it.bindEvents();
    it.initForms();
  }


  bindEvents() {
    jQuery.validator.addMethod("email", function(value, element) {
      var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(value);
    });

    jQuery.validator.addMethod("masked_phone", function(value, element) {
      return (value.indexOf('_') === -1);
    });

    jQuery.validator.addMethod("has_matching_option", function(value, element) {
      const $options = $(element).closest('._select').find('.site-form__select-dropdown li');
      const $mathcing_option = $options.filter(function(index, option) {
        const option_value = $(option).html().trim().toLowerCase();
        return option_value === value.trim().toLowerCase();
      });

      if($mathcing_option.length) {
        element.value = $mathcing_option.text();
        return true;
      } else {
        return false;
      }
    });
  }

  initForms() {

    if($('#feedback-form').length) {
        new FeedbackForm();
    }

    if($('#industry-subscription-form').length) {
      new IndustrySubscriptionForm();
    }

    if($('#news-subscription-form').length) {
      new NewsSubscribeForm();
    }
  }
}
