import Pagination from '../components/pagination';
import CustomSelect from '../components/custon-select';
import NewsList from '../components/news-list';

export default class News {

    constructor() {
        let it = this;

        // Варианты раскидывания классов карточек для десктопа
        it.desktop_cards_layouts = {
            1: ['_large'],
            2: ['_large', '_large'],
            3: ['_large', '_small', '_small'],
            4: ['_large', '_large', 
                '_large', '_large'],
            5: ['_large', '_small', '_small', 
                '_large', '_large'],
            6: ['_large', '_small', '_small', 
                '_small', '_small', '_large'],
            7: ['_large', '_small', '_small', 
                '_small', '_small', '_small', '_small'],
            8: ['_large', '_small', '', 
                '_small', '_small', '_large', 
                '_large', '_large'],
            9: ['_large', '_small', '_small', 
                '_small', '_small', '_large', 
                '_large', '_small', '_small'],
            10: ['_large', '_small', '_small',
                 '_small', '_small', '_large', 
                '_small', '_small', '_small', '_small'],
            11: ['_large', '_small', '_small',
                 '_small', '_small', '_large', 
                '_large', '_small', '_small',
                 '_large', '_large'],
            12: ['_large', '_small', '_small',
                 '_small', '_small', '_large',
                '_small', '_small', '_small', '_small',
                 '_large', '_large']
        }

        // Варианты раскидывания классов карточек для 768
        it.tablet_cards_layouts = {
            1: ['_large'],
            2: ['_large', '_small'],
            3: ['_small', '_small', '_small'],
            4: ['_large', '_small', 
                '_small', '_large'],
            5: ['_large', '_small', 
                '_small', '_small', '_small'],
            6: ['_large', '_small', 
                '_small', '_large',
                '_large', '_small'],
            7: ['_large', '_small', 
                '_small', '_large',
                '_small', '_small', '_small'],
            8: ['_large', '_small', 
                '_small', '_large',
                '_large', '_small',
                '_large', '_small'],
            9: ['_large', '_small', 
                '_small', '_large',
                '_large', '_small',
                '_small', '_small', '_small'],
            10: ['_large', '_small', 
                '_small', '_large',
                '_large', '_small',
                '_large', '_small',
                '_small', '_large'],
            11: ['_large', '_small', 
                '_small', '_large',
                '_large', '_small',
                '_large', '_small',
                '_small', '_small', '_small'],
            12: ['_large', '_small', 
                '_small', '_large',
                '_large', '_small',
                '_large', '_small',
                '_small', '_large', 
                '_large', '_small'],
        }

        
        it.$mobile_filters_wrap = $('.news__mobile-select');
        it.$form_block = $('.news-subscription');

        it.pagination = new Pagination($('.news__pagination'));
        it.news_list = new NewsList();

        const url_category = getUrlParam('category');
        const url_page = getUrlParam('page');
        const url_scroll = getUrlParam('scroll');

        it.category = url_category ? url_category : '';
        it.page = url_page ? url_page : 1;

        it.getCategories();
        it.getNewsPage(url_scroll);
        it.bindEvents();
    }


    bindEvents() {
        let it = this;

        $(window).on('breakpointChange', it.assignCardsClasses.bind(this));

        $('.news__pagination').on('page-change', function(event, params) {
            it.page = params.page;
            it.getNewsPage();
            $(window).scrollTop($('.news__main').offset().top);
        });

        $('body').on('click', '.news-card__link', function(event) {
            event.preventDefault();
            const current_scroll = $(window).scrollTop();
            const href = this.getAttribute('href');
            //ss = saved scroll
            location.href = href + '&ss=' + current_scroll;
        })
    }


    initFilters(active_filter) {
        let it = this;

        it.$filters = $('.news__filter');
        it.mobile_select = new CustomSelect(it.$mobile_filters_wrap);

        it.$filters.on('click', function() {
            if(this.classList.contains('_active')) {
                this.classList.remove('_active');
                it.category = '';
            } else {
                it.$filters.removeClass('_active');
                this.classList.add('_active');
                it.category = this.getAttribute('data-filter');
            }
            if(active_filter) {
                it.mobile_select
            }
            it.page = 1;
            it.getNewsPage();
        })

        it.$mobile_filters_wrap.on('option-select', function(event, params) {
            it.category = params.option_node.getAttribute('data-filter');
            it.page = 1;
            it.getNewsPage();
        })
    }


    renderCards(items) {
        let it = this;

        it.cards_count = items.length;

        $('.news-card').remove();

        items = it.news_list.sortCardsByDate(items);

        items.forEach((card, index) => {
            const card_html = it.news_list.createCardHtml(card);
            if(index < 6 || it.cards_count === 7) {
                $(card_html).insertBefore(it.$form_block)
            } else {
                $('.news__items').append(card_html);
            }
        })

        it.assignCardsClasses();
        it.addReturnData();
    }


    assignCardsClasses() {
        let it = this;

        let layout;

        if(window.breakpoint === 'desktop') {
            layout = it.desktop_cards_layouts[it.cards_count]
        } else {
            layout = it.tablet_cards_layouts[it.cards_count]
        }

        $('.news-card').each(function(index, card) {
            card.classList.remove('_large');
            card.classList.remove('_small');
            card.classList.add(layout[index]);
        })
    }


    addReturnData() {
        let it = this;

        $('.news-card__link').each(function(index, link) {
            const href = this.getAttribute('href');
            // rc - return category, rp - return page
            const new_href = href + `&rc=${it.category}&rp=${it.page}`;
            link.setAttribute('href', new_href);
        });
    }


    getCategories() {
        let it = this;

        $.ajax({
            headers: {
                'X-localization': SITE_LANGUAGE.toLowerCase(),
            },
            url: '/api/categories',
            method: 'GET',
            complete: (data) => {
                if(data.responseJSON.data) {
                    let active_filter = null;
                    data.responseJSON.data.forEach((item) => {
                        let is_active = it.category === item.attributes.slug ? true : false;
                        if(is_active) {
                            it.$mobile_filters_wrap.find('input').val(item.attributes.title);
                        }
                        const item_html = `
                            <button class="news__filter ${is_active ? '_active' : ''} tag-btn" data-filter=${item.attributes.slug}>
                                <span>${item.attributes.title}</span>
                            </button>
                        `;
                        const mobile_item_html = `
                            <li data-filter=${item.attributes.slug}>${item.attributes.title}</li>
                        `;
                        $('.news__filters').append(item_html);
                        $('.news__mobile-filters ul').append(mobile_item_html);
                    })
                    it.initFilters();
                }
            }
        })
    }


    getNewsPage(scroll) {
        let it = this;

        it.news_list.getNews(it.page, it.category).then((data) => {
            it.renderCards(it.news_list.sortCardsByDate(data.data));
            it.pagination.setPagesCount(data.meta.last_page);
            it.pagination.setCurrentPage(data.meta.current_page);
            if(scroll) {
                setTimeout(() => {
                    window.scrollTo(0, +scroll)
                }, 150)
            }
        })
    }
}