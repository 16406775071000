import ProductsTypesList from '../components/products-types-list';

export default class Products {

    constructor() {
        let it = this;

        it.products_types_list = new ProductsTypesList();
        it.products_loaded = false;
        it.filters_loaded = false;

        it.getProductsTypes();
    }


    getProductsTypes() {
        let it = this;

        it.products_types_list.getProductsTypes().then((data) => {
            // console.log(data);
            it.products_types = data.data;
            it.products_types.forEach((item) => {
                $('.product-cards-wrap').append(
                    it.products_types_list.createCardHtml(item)
                )
            })
            it.getProductsTypesCategories();
        })
    }


    getProductsTypesCategories() {
        let it = this;

        it.products_types_list.getProductsTypesCategories().then((data) => {
            data.data.forEach((item) => {
                let category_has_items = false;
                it.products_types.forEach((product_type) => {
                    if(product_type.attributes.category_slug === item.attributes.slug) {
                        category_has_items = true;
                    }
                })
                if(category_has_items) {
                    $('.products__filters-inner').append(
                        `<button data-filter=${item.attributes.slug}>${item.attributes.title}</button>`
                    )
                }
            })
            it.initFilters();
        })
    }


    initFilters() {
        let it = this;

        it.$filters = $('.products__filters button');
        it.$cards = $('.product-card');
        
        it.$filters.on('click', function() {
            const filter = this.getAttribute('data-filter');
            it.setFilter(filter);
        })

        it.setFilter('all');
    }


    setFilter(filter) {
        let it = this;

        it.$cards.removeClass('_active');
        it.$filters.removeClass('_active');
        
        it.$filters.filter(function() {
            return this.getAttribute('data-filter') === filter;
        }).addClass('_active');

        if(filter === 'all') {
            it.$cards.addClass('_active');
        } else {
            it.$cards.filter(function() {
                return this.getAttribute('data-filter') === filter;
            }).addClass('_active');
        }
    }
}