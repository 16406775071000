import { tablet_breakpoint, mobile_breakpoint } from '../constants';
import Pagination from '../components/pagination';

export default class Career {

    constructor() {
        let it = this;

        it.scrollNavigation();
        it.initInstructionsBlocks();
        it.initSliders();
    }


    initInstructionsBlocks() {
        $('.business__instructions-block h5').on('click', function(event) {
            event.target.closest('.business__instructions-block').classList.toggle('_opened');
        })
    }


    scrollNavigation() {
        let pixels_to_scroll = 0;
        const $prev_links = $('.business__page-link._active').prevAll('.business__page-link');
        $prev_links.each(function(index, link) {
            pixels_to_scroll += link.offsetWidth;
        })
                
        $('.business__pages-links').scrollLeft(pixels_to_scroll)
    }


    initSliders() {
        let it = this;

        if($('.business__system-slider').length) {
            $('.business__system-slider').slick({
                prevArrow: `
                    <button class="business__system-slider-btn arrow-btn _prev">
                        <svg><use xlink:href="#icon-arrow-left-short"></svg>
                    </button>`,
                nextArrow: `
                    <button class="business__system-slider-btn arrow-btn _next">
                        <svg><use xlink:href="#icon-arrow-right-short"></svg>
                    </button>`,
            })
        }

        if($('.business-society__slider').length) {
            $('.business-society__slider').slick({
                fade: true,
                speed: 900,
                prevArrow: `
                    <button class="business-society__slider-btn arrow-btn _prev">
                        <svg><use xlink:href="#icon-arrow-left-short"></svg>
                    </button>`,
                nextArrow: `
                    <button class="business-society__slider-btn arrow-btn _next">
                        <svg><use xlink:href="#icon-arrow-right-short"></svg>
                    </button>`,
            })

            const height = $('.business-society__slider .slick-track').height();
            $('.business-society__slider .slick-slide').css('height', height + 'px' );

            $(window).on('brealpointChange', function() {
                const height = $('.business-society__slider .slick-track').height();
                $('.business-society__slider .slick-slide').css('height', height + 'px' );
            }) 
        }
    }


    initFeedback() {
        let it = this;

        it.pagination = new Pagination($('.business__feedback-pagination'));
        it.pagination.setPagesCount(5);
        it.pagination.setCurrentPage(1);
    }
}