import CustomSelect from '../components/custon-select';
import ProductsTypesList from '../components/products-types-list';
import PriceList from '../components/price-list';

export default class Product {

    constructor() {
        let it = this;

        it.basises_select = $('.price-list__select');
        it.$content_wrap = $('.price-list__content-inner');
        it.price_list = new PriceList();
        it.products_types_list = new ProductsTypesList();

        it.product_type_id = getUrlParam('id');
        it.product_type = null;

        if(it.product_type_id) {
            new CustomSelect(it.basises_select);
            it.getProductInfo();
            it.getBasises();
            it.getProducts();
        } else {
            const notFoundMessage = it.getCurrentLang() === 'en' ? 'The product was not found' : 'Продукт не найден';
            $('.product__intro-text').append(`<h1>${notFoundMessage}</h1>`);
        }
    }

    getCurrentLang() {
        return $('html').attr('lang') || 'ru';
    }

    getProductInfo() {
        let it = this;

        it.products_types_list.getProductsTypes().then((data) => {
            it.product_type = it.products_types_list.getProductTypeById(it.product_type_id)[0];

            if (!it.product_type) {
                const notFoundMessage = it.getCurrentLang() === 'en' ? 'The product was not found' : 'Продукт не найден';
                $('.product__intro-text').append(`<h1>${notFoundMessage}</h1>`);
                return;
            }

            $('.product').addClass('_loaded');
            
            $('.breadcrumbs__inner ul').append(`<li><span class="breadcrumbs__current-page">${it.product_type.attributes.title}</span></li>`);
            $('.product__intro-text').append(
                `
                <h1>${it.product_type.attributes.title}</h1>
                <p class="text">${it.product_type.attributes.description}</p>
                `
            )
            $('.product__intro-image div').css({
                backgroundImage: `url(/storage/${it.product_type.attributes.image})`
            })
        })
    }


    getProducts() {
        let it = this;

        it.price_list.getProducts().then((data) => {
            it.products = it.price_list.filterProductsByType(data.data, it.product_type_id);
            it.products = it.price_list.groupProductsByClass(it.products);

            it.products.forEach((item) => {
                it.$content_wrap.append(
                    it.price_list.createProductHtml(item)
                )
            })
        })
    }


    getBasises() {
        let it = this;

        $('.price-list__filters')[0].reset();

        it.price_list.getBasises(it.product_type_id).then((data) => {

            data.data.forEach((item) => {
                $('.product__basises-select ul').append(
                    `<li data-basis=${item.id}>${item.attributes.title}</li>`
                )
                // it.$content_wrap.append(
                //     it.price_list.createBasisHtml(item)
                // )
            })

            it.initSelects();
        })
    }


    initSelects() {
        let it = this;

        it.basises_select.on('option-select', function(event, params) {
            const selected_basis_id = params.option_node.getAttribute('data-basis');

            it.$content_wrap.empty();

            if(!selected_basis_id.length) {
                it.products.forEach((item) => {
                    it.$content_wrap.append(
                        it.price_list.createProductHtml(item)
                    )
                })
            } else {
                const selected_basises = it.price_list.getBasisById(selected_basis_id);
                selected_basises.forEach((item) => {
                    it.$content_wrap.append(
                        it.price_list.createBasisHtml(item, it.product_type_id)
                    )
                })
            }
        });
    }
}