export default class NewsList {

    getNews(page=1, category="") {
        let it = this;

        return new Promise((resolve, reject) => {
            $.ajax({
                headers: {
                    'X-localization': SITE_LANGUAGE.toLowerCase(),
                },
                url: `/api/articles?page=${page}&category_slug=${category}`,
                method: 'GET',
                complete: (data) => {
                    // console.log(data)
                    if(data.responseJSON.data) {
                        resolve(data.responseJSON)
                    }
                }
            })
        })
    }


    createCardHtml(card, card_class='') {
        let it = this;

        let language_string;

        switch(SITE_LANGUAGE) {
            case('RU'):
                language_string = '';
                break;
            case('EN'):
                language_string = '/en';
                break;
            default:
                language_string = '';
                break;
        }

        return `
        <div class="news-card ${card_class}">
            <a class="news-card__link" href="${language_string}/news-article?id=${card.id}"></a>
            <div class="news-card__type">${card.attributes.category.title}</div>
            <div class="news-card__image" style="background-image: url(/storage/${card.attributes.preview})"></div>
            <div class="news-card__about">
                <div class="news-card__date">${it.createHumanDate(card.attributes.date)}</div>
                <div class="news-card__title">${card.attributes.title}</div>
            </div>
        </div>
        `
    }


    createHumanDate(date) {
        let default_msg = 'Дата не указана';

        if(SITE_LANGUAGE === 'EN') {
            default_msg = 'Date not specified';
        }
        if(!date) return default_msg;

        date = new Date(date);

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        let formatted_month = '';

        if(SITE_LANGUAGE === 'RU') {
            switch(month) {
                case 0: 
                    formatted_month = 'января';
                    break;
                case 1: 
                    formatted_month = 'февраля';
                    break;
                case 2: 
                    formatted_month = 'марта';
                    break;
                case 3: 
                    formatted_month = 'апреля';
                    break;
                case 4: 
                    formatted_month = 'мая';
                    break;
                case 5: 
                    formatted_month = 'июня';
                    break;
                case 6: 
                    formatted_month = 'июля';
                    break;
                case 7: 
                    formatted_month = 'августа';
                    break;
                case 8: 
                    formatted_month = 'сентября';
                    break;
                case 9: 
                    formatted_month = 'октября';
                    break;
                case 10: 
                    formatted_month = 'ноября';
                    break;
                case 11: 
                    formatted_month = 'декабря';
                    break;
            }
        }

        if(SITE_LANGUAGE === 'EN') {
            switch(month) {
                case 0: 
                    formatted_month = 'january';
                    break;
                case 1: 
                    formatted_month = 'february';
                    break;
                case 2: 
                    formatted_month = 'march';
                    break;
                case 3: 
                    formatted_month = 'april';
                    break;
                case 4: 
                    formatted_month = 'may';
                    break;
                case 5: 
                    formatted_month = 'june';
                    break;
                case 6: 
                    formatted_month = 'july';
                    break;
                case 7: 
                    formatted_month = 'august';
                    break;
                case 8: 
                    formatted_month = 'september';
                    break;
                case 9: 
                    formatted_month = 'october';
                    break;
                case 10: 
                    formatted_month = 'november';
                    break;
                case 11: 
                    formatted_month = 'december';
                    break;
            }
        }

        return(`${day} ${formatted_month} ${year}`)
    }


    sortCardsByDate(cards) {
        cards.forEach((card) => {
            if(!card.attributes.date) {
                card.attributes.date = 0;
            }
        })
        return cards.sort((a,b) => {
            return new Date(b.attributes.date) - new Date(a.attributes.date);
        })
    }
}