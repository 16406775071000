export default class NotFound {

    constructor() {
        let it = this;

        it.$seeds = $('.not-found__seed');

        if(!IS_MOBILE) {
            it.initParallax();
        }
    }


    initParallax() {
        let it = this;

        $(document).on('mousemove', it.animateSeeds.bind(it));
    }


    animateSeeds(event) {
        let it = this;

        const   x_from_page_center = event.clientX - $(window).width()/2,
                y_from_page_center = event.clientY - $(window).height()/2;

        it.$seeds.each(function(index, seed) {
            const   parallax_mod = +seed.getAttribute('data-parallax-mod'),
                    x_translate = parallax_mod * x_from_page_center/-30,
                    y_translate = parallax_mod * y_from_page_center/-30,
                    style_string = `translateX(${x_translate}px) translateY(${y_translate}px)`;

            seed.style.transform = style_string;
        })
    }
}