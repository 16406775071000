export default class CustomSelect {

    constructor($container) {
        let it = this;

        it.$container = $container;
        it.$input = $container.find('.site-select__input');
        it.$dropdown = $container.find('.site-select__dropdown');
        // it.$options = $container.find('.site-select__dropdown li');

        it.init();
    }


    init() {
        let it = this;

        it.scrollbar = new PerfectScrollbar(it.$dropdown[0], {
            wheelPropagation: false,
        });

        it.$input.on('click', function(event) {
            const is_opened = it.$container.hasClass('_opened');
            $('.site-select').removeClass('_opened');

            if(is_opened) {
                it.$container.removeClass('_opened');
            } else {
                it.$container.addClass('_opened');
            }
        })

        it.$container.on('mousedown', 'li', function() {
            const value = this.textContent.trim();
            it.$input.val(value);
            it.$container.trigger('option-select', {
                option_node: this,
                value: value,
            })
            $('.site-select').removeClass('_opened');
        })
    }


    selectOption($option) {
        let it = this;

        it.$input.val($option.textContent.trim())
    }
}