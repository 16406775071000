export default class FeedbackForm {

    constructor() {
        let it = this;

        it.$form = $('#feedback-form');
        it.$submit_btn = it.$form.find('button[type="submit"]');

        it.error_messages_ru = {
          name: {
            required: "Введите имя",
          },
          email: { 
            required: "Введите E-mail",
            email: "Некорректный E-mail",
          },
          message: {
            required: "Введите сообщение",
          },
        };

        it.error_messages_en = {
          name: {
            required: "Enter full name",
          },
          email: { 
            required: "Enter E-mail",
            email: "Invalid E-mail",
          },
          message: {
            required: "Enter message",
          },
        };

        switch(SITE_LANGUAGE) {
          case('RU'):
            it.error_messages = it.error_messages_ru;
            break;
          case('EN'):
            it.error_messages = it.error_messages_en;
            break;
          default: it.error_messages = it.error_messages_ru;
        }

        it.initForm();
    }


    initForm() {
        let it = this;

        it.$form[0].reset();

        let validator = it.$form.validate({
            errorElement: "span",
            focusInvalid: false,
            highlight: function(element) {
              $(element).addClass("error").removeClass("valid");
              it.$submit_btn.addClass('disabled');
            },
            unhighlight: function(element) {
              $(element).removeClass("error").addClass("valid");
            },
            submitHandler: function() {
              it.handleFormSubmit();
            },
            rules: {
              "name": {required: true},
              "email": {required: true, email: true},
              "message": {required: true},
            },
            messages: it.error_messages
        });
    }

    
    handleFormSubmit() {
        let it = this;

        it.$submit_btn.attr('disabled', true);

        $.ajax({
          url: it.$form.attr('action'),
          type: it.$form.attr('method'),
          data: new FormData(it.$form[0]),
          processData: false,
          contentType: false,
          complete: (data) => {
            it.$submit_btn.removeAttr('disabled');
            if(data.status === 200 || data.status === 201) {
              it.$form.addClass('_has-success');
            } else {
              switch(SITE_LANGUAGE) {
                case('RU'):
                  alert('Код ошибки: ' + data.status);
                  break;
                case('EN'):
                  alert('Error code: ' + data.status);
                  break;
                default: alert('Код ошибки: ' + data.status);
              }
            }
          }
        })
    }
}