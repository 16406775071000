import { tablet_breakpoint } from '../constants';

import NewsList from '../components/news-list';

export default class Career {

    constructor() {
        let it = this;

        it.news_list = new NewsList();

        it.initSliders();
        it.bindEvents();
        it.getNewsCards();
        it.getVacancies();
        it.getPrograms();
    }


    bindEvents() {
        $(document).on('click', '.js-form-anchor', function(event) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $('.feedback').offset().top
            }, 1200)
        })
    }


    initSliders() {
        let it = this;

        $('.career__features-slider').slick({
            prevArrow: `
                <button class="career__features-slider-btn arrow-btn _prev">
                    <svg><use xlink:href="#icon-arrow-left-short"></svg>
                </button>`,
            nextArrow: `
                <button class="career__features-slider-btn arrow-btn _next">
                    <svg><use xlink:href="#icon-arrow-right-short"></svg>
                </button>`,
        });

        $('.career__growth-slider').slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            prevArrow: `
                <button class="career__growth-slider-btn arrow-btn _prev">
                    <svg><use xlink:href="#icon-arrow-left-short"></svg>
                </button>`,
            nextArrow: `
                <button class="career__growth-slider-btn arrow-btn _next">
                    <svg><use xlink:href="#icon-arrow-right-short"></svg>
                </button>`,
            responsive: [
                {
                    breakpoint: tablet_breakpoint,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });

        $('.career__growth-slider').on('setPosition', function () {
            $(this).find('.slick-slide').height('auto');
            var slickTrack = $(this).find('.slick-track');
            var slickTrackHeight = $(slickTrack).height();
            $(this).find('.slick-slide').css('height', slickTrackHeight + 'px');
        });
    }


    getVacancies() {
        let it = this;

        $.ajax({
            headers: {
                'X-localization': SITE_LANGUAGE.toLowerCase(),
            },
            url: '/api/vacancies',
            type: 'GET',
            complete: (data) => {
                if(data.responseJSON.data) {
                    data.responseJSON.data.forEach((item) => {
                        $('.career__vacancies-list').append(it.createVacancyHtml(item))
                    })
                }
            }
        })
    }


    createVacancyHtml(item) {
        let counter_title = 'Вакансий';

        if(SITE_LANGUAGE === 'EN') {
            counter_title = 'Number of vacancies';
        }

        return `
        <div class="career__vacancy">
            <div class="career__vacancy-name">
                <a href=${item.attributes.link} target="_blank"></a>
                ${item.attributes.title}
            </div>
            <div class="career__vacancy-counter text">
                <img src="/img/icons/hh.svg" alt="hh.ru">
                <span>${counter_title}: ${item.attributes.count}</span>  
            </div>
        </div>
        `
    }


    getPrograms() {
        let it = this;

        $.ajax({
            headers: {
                'X-localization': SITE_LANGUAGE.toLowerCase(),
            },
            url: '/api/programs',
            type: 'GET',
            complete: (data) => {
                console.log(data)
                if(data.responseJSON.data) {
                    data.responseJSON.data.forEach((item) => {
                        $('.career__programs-inner').append(it.createProgramHtml(item))
                    })
                }
            }
        })
    }


    createProgramHtml(item) {
        let reply_string = 'Оставить свои данные';

        if(SITE_LANGUAGE === 'EN') {
            reply_string = 'Leave my contacts';
        }

        const formatted_description = item.attributes.description.replace(new RegExp('<ul>', 'g'), '<ul class="simple-list">');
        return `
        <div class="program">
            <div class="program__header">
                <div class="program__title">${item.attributes.title}</div>
                <button class="program__trigger-btn">
                    <svg>
                        <use xlink:href="#icon-direction-down"></use>
                    </svg>
                </button>
            </div>
            <div class="program__content text">
                <div class="program__subtitle text-large">
                    ${item.attributes.short_desc}
                </div>
                ${formatted_description}
                <br/>
                <a href="#" class="program__link btn js-form-anchor">
                    <div class="btn__bg"></div>
                    <div class="btn__text">${reply_string}</div>
                </a>
            </div>
        </div> 
        `
    }


    getNewsCards() {
        let it = this;

        it.news_list.getNews(1, 'career').then((data) => {
            const cards = it.news_list.sortCardsByDate(data.data);
            for(let i=0; i<4; i++) {
                if(cards[i]) {
                    $('.career__news-cards').append(
                        it.news_list.createCardHtml(cards[i])
                    )
                }
            }
        })
    }
}