import CustomSelect from '../components/custon-select';
import ProductsTypesList from '../components/products-types-list';
import PriceList from '../components/price-list';

export default class Prices {

    constructor() {
        let it = this;

        it.product_type_id = '';
        it.basis_id = '';
        it.loaded_modules = 0;

        it.basises_select = $('.js-basises-select');
        it.product_type_select = $('.js-product-type-select');
        it.$content_wrap = $('.price-list__content-inner');

        new CustomSelect(it.basises_select);
        new CustomSelect(it.product_type_select);

        it.price_list = new PriceList();
        it.products_types_list = new ProductsTypesList();
        

        it.getBasises();
        it.getProductsTypes();
        it.getProducts();
    }


    checkPageLoadState() {
        let it = this;

        if(it.loaded_modules === 3) {
            it.initSelects();
        }
    }


    getBasises() {
        let it = this;

        $('.price-list__filters')[0].reset();

        it.price_list.getBasises().then((data) => {
            it.basises = data.data;

            it.basises.forEach((item) => {
                it.basises_select.find('ul').append(
                    `<li data-basis=${item.id}>${item.attributes.title}</li>`
                )
                it.$content_wrap.append(
                    it.price_list.createBasisHtml(item)
                )
            })

            it.loaded_modules += 1;
            it.checkPageLoadState();
        })
    }


    getProducts() {
        let it = this;

        it.price_list.getProducts().then((data) => {
            it.products = data.data;
            it.loaded_modules += 1;
            it.checkPageLoadState();
        })
    }


    getProductsTypes() {
        let it = this;

        it.products_types_list.getProductsTypes().then((data) => {
            it.products_types = data.data;
            it.products_types.forEach((item) => {
                if(item.attributes.currency) {
                    it.product_type_select.find('ul').append(
                        `<li data-type=${item.id} class="js-price-list-option">${item.attributes.title}</li>`
                    )
                }
            })
            it.loaded_modules += 1;
            it.checkPageLoadState();
        })
    }


    initSelects() {
        let it = this;

        it.basises_select.on('option-select', function(event, params) {
            it.basis_id = params.option_node.getAttribute('data-basis');
            it.showItems();
        })
        

        it.product_type_select.on('option-select', function(event, params) {
            it.product_type_id = params.option_node.getAttribute('data-type');        
            // const product_type = it.products_types_list.getProductTypeById(it.product_type_id);
            it.showItems();
        })
    }


    getProductTypeByProductId(product_id) {
        let it = this;

        let type = null;

        it.products.forEach((product) => {
            if(product.id == product_id) {
                type = it.products_types_list.getProductTypeById(product.relationships.product_type.id)
            }
        })

        return type[0];
    }


    showItems() {
        let it = this;

        it.$content_wrap.empty();

        if(!it.basis_id.length && !it.product_type_id.length) {
            it.basises.forEach((item) => {
                it.$content_wrap.append(
                    it.price_list.createBasisHtml(item)
                )
            })
        }
        else if(!it.basis_id.length && it.product_type_id.length) {
            const selected_products = it.price_list.filterProductsByType(it.products, it.product_type_id);
            const grouped_products = it.price_list.groupProductsByClass(selected_products);
            grouped_products.forEach((item) => {
                it.$content_wrap.append(
                    it.price_list.createProductHtml(item)
                )
            })
        }  
        else if(it.basis_id.length && !it.product_type_id.length) {
            const selected_basis = it.price_list.getBasisById(it.basis_id)[0];
            it.$content_wrap.append(
                it.price_list.createBasisHtml(selected_basis, it.product_type_id)
            )
        }
        else if (it.basis_id.length && it.product_type_id.length) {
            const selected_basis = it.price_list.getBasisById(it.basis_id)[0];
            let basis_has_selected_product_type = false;
            selected_basis.relationships.products.forEach((product) => {
                if(it.price_list.getProductTypeById(product.id) == it.product_type_id) {
                    basis_has_selected_product_type = true;
                }
            })
            if(basis_has_selected_product_type) {
                it.$content_wrap.append(
                    it.price_list.createBasisHtml(selected_basis, it.product_type_id)
                )
            } else {
                it.$content_wrap.append(
                    `<div class="price-list__error">Выбранный базис не поставляет данный тип продукции</div>`
                )
            }
        }
    }
}