import PartnersList from '../components/partners-list';
import Pagination from '../components/pagination';

export default class Partners {

    constructor() {
        let it = this;

        it.partner_container = $('.business__partners-cards');

        it.partners_list = new PartnersList();
        it.pagination = new Pagination($('.business__feedback-pagination'));

        it.getPartners(1, false);
        it.bindEvents();
    }


    bindEvents() {
        let it = this;

        $('.business__partners').on('page-change', function(event, params) {
            it.getPartners(params.page);
        })
    }


    getPartners(page=1, scroll_to_top=true) {
        let it = this;

        it.partners_list.getPartners(page).then((data) => {
            if(data.meta.last_page > 1) {
                it.pagination.setPagesCount(data.meta.last_page);
                it.pagination.setCurrentPage(data.meta.current_page);
            }

            it.partner_container.empty();
            data.data.forEach((item) => {
                it.partner_container.append(
                    it.partners_list.createCardHtml(item.attributes)
                )
            })
            if(scroll_to_top) {
                $(window).scrollTop($('#partners').offset().top)
            }
        });
    }
}