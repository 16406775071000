import NewsList from '../components/news-list';

export default class Article {

    constructor() {
        let it = this;

        it.news_list = new NewsList();
        it.article_id = +getUrlParam('id');
        it.next_article_link = null;

        if(it.article_id) {
            it.getArticleData();
        }

        it.createReturnLink();
    }


    getArticleData() {
        let it = this;

        let error_string = 'Ошибка 404. Статья не найдена';

        if(SITE_LANGUAGE === 'EN') {
            error_string = 'Error 404. Article not found';
        }

        $.ajax({
            headers: {
                'X-localization': SITE_LANGUAGE.toLowerCase(),
            },
            url: `/api/articles/${it.article_id}`,
            method: 'GET',
            complete: (data) => {
                // console.log(data);
                if(data.responseJSON.attributes) {
                    it.next_article_link = data.responseJSON.links.next;
                    if(it.next_article_link) {
                        it.getNextArticle();
                    }
                    it.getOtherArticles();
                    it.createArticleHtml(data.responseJSON.attributes);
                    it.setSharingParams(data.responseJSON.attributes);
                } else {
                    $('.article__intro-text').append(`<h1>${error_string}</h1>`)
                }
            }
        })
    }


    createReturnLink() {
        const return_category = getUrlParam('rc');
        const return_page = getUrlParam('rp');
        const saved_scroll = getUrlParam('ss');
        
        const $link = $('.article__return-link');
        let href = $link.attr('href');

        if(return_page) {
            href += `?page=${return_page}`;
        } else {
            href += `?page=1`;
        }
        
        if(return_category && return_category.length) {
            href += `&category=${return_category}`;
        }

        if(saved_scroll) {
            href += `&scroll=${saved_scroll}`; 
        }

        $link.attr('href', href);
    }


    setSharingParams(article) {
        const $sharing_block = $('.ya-share2');

        $sharing_block.attr('data-title', article.title);

        let image_path = '';
        if(article.preview) {
            image_path = `http://${location.hostname}/storage/${article.preview}`;
        } else 
        if(article.image) {
            image_path = `http://${location.hostname}/${article.image}`;
        }

        // console.log(image_path);

        if(article.preview) {
            $sharing_block.attr('data-image', image_path);
        }
        
        $('head').append(`<script src="https://yastatic.net/share2/share.js" async="async"></script>`);
    }


    createArticleHtml(article) {
        let it = this;

        const hours = new Date(article.date).getUTCHours();
        const minutes = new Date(article.date).getMinutes();

        $('.article__intro-text').append(
            `
            <h1>${article.title}</h1>
            <div class="article__intro-footer">
                <div class="article__tag tag-btn">
                    <span>${article.category.title}</span>
                </div>
                <span class="text-3"><span>${pad(hours, 2)}:${pad(minutes, 2)}</span> ${it.news_list.createHumanDate(article.date)}</span>
            </div>
            `
        )

        let html = '';

        if(article.image && article.image.length) {
            html += `<div class="article__images">`

            article.image.forEach((image) => {
                html += `
                    <div class="article__image" style="background-image: url(/storage/${image})"></div>
                `;
            });
            html += `</div>`
        }

        if(article.image && article.image.length && article.image_title) {
            html += `
                <div class="article__image-description text-3">
                    <p>${article.image_title}</p>
                </div>
            `
        }

        if(article.quote) {
            html += `
                <blockquote class="article__quote decorated-quote text-large">
                    <span>&laquo;${article.quote}&raquo;</span>
                </blockquote>
            `
        }

        html += `
            <div class="article__text text">
                ${article.body}
            </div>
        `;

        $('.article__main-content').append(html);
        if(article.image && article.image.length > 1) {
            it.initSlider();
        }
        $('.article__main').addClass('_loaded');
    }


    initSlider() {
        let it = this;

        $('.article__images').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: `
                <button class="article__slider-btn arrow-btn _prev">
                    <svg><use xlink:href="#icon-arrow-left-short"></svg>
                </button>`,
            nextArrow: `
                <button class="article__slider-btn arrow-btn _next">
                    <svg><use xlink:href="#icon-arrow-right-short"></svg>
                </button>`,
        })
    }


    getNextArticle() {
        let it = this;

        let subtitle_text = 'Следующая новость';

        if(SITE_LANGUAGE === 'EN') {
            subtitle_text = 'Next news';
        }

        $.ajax({
            headers: {
                'X-localization': SITE_LANGUAGE.toLowerCase(),
            },
            url: it.next_article_link,
            method: 'GET',
            complete: (data) => {
                // console.log(data);
                const article_data = data.responseJSON.attributes;
                if(article_data) {
                    $('.article__next-inner').append(
                        `
                        <div class="article__next-article">
                            <a href="/news-article?id=${data.responseJSON.id}" class="article__next-article-link"></a>
                            <p class="article__next-subtitle text-bold">${subtitle_text}</p>
                            <p class="article__next-date">${it.news_list.createHumanDate(article_data.date)}</p>
                            <p class="article__next-title text-large">
                                ${article_data.title}
                            </p>
                        </div>
                        `
                    );
                    $('.article__next').addClass('_loaded');
                }
            }
        })
    }


    getOtherArticles() {
        let it = this;

        it.news_list.getNews().then((data) => {
            console.log(data)
            if(!it.next_article_link) {
                it.next_article_link = data.links.first_record;
                it.getNextArticle();
            }
            const cards = it.news_list.sortCardsByDate(data.data);
            for(let i=0; i<4; i++) {
                if(cards[i]) {
                    $('.article__news-cards').append(
                        it.news_list.createCardHtml(cards[i])
                    )
                }
            }
            $('.article__more').addClass('_loaded');
        })
    }
}