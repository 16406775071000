export default class Geography {

    constructor($container) {
        let it = this;

        it.$filters_wrap = $('.geography__filters');
        it.$filters = $container.find('.geography__filters button');
        it.$slides = $container.find('.geography__slide');
        it.$overlays = $container.find('.geography__overlay');

        it.init();
    }


    init() {
        let it = this;

        it.$filters.on('click', function(event) {
            const slide_name = event.target.closest('button').getAttribute('data-slide');
            it.activateSlide(slide_name);
        })
    }

    
    activateSlide(name) {
        let it = this;

        it.$filters.removeClass('_active');
        it.$slides.removeClass('_active');
        it.$overlays.removeClass('_active');

        const $filter = it.$filters.filter(function() {
            return this.getAttribute('data-slide') == name;
        }).addClass('_active');

        it.$slides.filter(function() {
            return this.getAttribute('data-slide') == name;
        }).addClass('_active');

        it.$overlays.filter(function() {
            return this.getAttribute('data-slide') == name;
        }).addClass('_active');

        const filter_pos = it.$filters_wrap.scrollLeft() + $filter.offset().left;
        it.$filters_wrap.scrollLeft(filter_pos);
    }
}