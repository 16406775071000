import jQuery_touch from 'jquery-touch-events';

import Helpers from './helpers';
import AllPages from './pages/all-pages';

import Mainpage from './pages/mainpage';
import Products from './pages/products';
import Product from './pages/product';
import Prices from './pages/prices';
import Contacts from './pages/contacts';
import News from './pages/news';
import Article from './pages/article';
import Career from './pages/career';
import Business from './pages/business';
import AboutUs from './pages/about-us';
import Partners from './pages/partners';
import NotFound from './pages/not-found';

import Geography from './components/geography';
import Forms from './components/forms/forms';
import Programs from './components/programs';

$(document).ready(() => {

  if($('html').hasClass('mobile')) {
    window.IS_MOBILE = true;
  } else {
    window.IS_MOBILE = false;
  }

  new Helpers();
  new AllPages();
  new Forms();
  new Programs();

  $('.geography').each(function(index, container) {
    new Geography($(container))
  })

  if($('.mainpage').length) new Mainpage();
  if($('.products').length) new Products();
  if($('.product').length) new Product();
  if($('.prices').length) new Prices();
  if($('.contacts').length) new Contacts();
  if($('.news').length) new News();
  if($('.article').length) new Article();
  if($('.career').length) new Career();
  if($('.about-us').length) new AboutUs();
  if($('.partners').length) new Partners();
  if($('.business').length) new Business();
  if($('.not-found').length) new NotFound();
  
})

