export default class AllPages {

    constructor() {
        let it = this;

        it.saved_scroll = window.pageYOffset;
        it.fluent_header = $('.header').hasClass('_scroll-dependant') ? true : false;

        it.bindEvents();
        it.initMenu();
        it.initLanguagePanels();
        it.setActiveNavLink();

        $(window).on('scroll', function() {
            it.checkHeaderState();
            it.saved_scroll = window.pageYOffset;
        });

        it.checkHeaderState();
    }


    bindEvents() {
        $(document).on('click', function(event) {
            if(!event.target.closest('.site-select')) {
                $('.site-select').removeClass('_opened');
            }
        })

        $('.numbered-feature__title').on('click', function(event) {
            event.target.closest('.numbered-feature').classList.toggle('_opened');
        })
    }


    checkHeaderState() {
        let it = this;

        let direction;

        if(it.saved_scroll < window.pageYOffset) {
            direction = 'down';
        } else {
            direction = 'up';
        }
        
        if(window.pageYOffset < 80 && it.fluent_header) {
            $('.header').addClass('_transparent');
        } else 
        if(!direction || direction === 'up') {
            $('.header').removeClass('_transparent');
        }
        
        if(direction === 'down' && window.pageYOffset > 80) {
            $('.header').addClass('_hidden');
        } else {
            $('.header').removeClass('_hidden');
        }
    }


    initMenu() {
        let it = this;

        let timeout = false;
        let saved_scroll = null;

        $('.header__nav-trigger').on('click', function() {
            if(!timeout) {
                timeout = true;

                if($('body').hasClass('_menu-opened')) {
                    $('body').removeClass('_menu-opened');
                    window.scrollTo(0, saved_scroll);
                } else {
                    saved_scroll = window.pageYOffset;
                    $('body').addClass('_menu-opened');
                }

                setTimeout(() => {
                    timeout = false
                }, window.breakpoint === 'desktop' ? 1300 : 0);
            }
        });
    }


    initLanguagePanels() {
        let it = this;

        $('.header__language-trigger').on('click', function() {
            $('.header__language-panel').toggleClass('_opened');
        })

        $('.menu__language-trigger').on('click', function() {
            $('.menu__mobile-controls-wrap').toggleClass('_opened');
        })

        $('body').on('click', function(event) {
            if(!event.target.closest('.header__language')) {
                $('.header__language-panel').removeClass('_opened');
            }

            if(!event.target.closest('.menu__language')) {
                $('.menu__mobile-controls-wrap').removeClass('_opened');
            }
        })
    }


    setActiveNavLink() {
        let path = window.location.pathname;
        const $links = $('.menu__list a').add('.header__nav a');
        console.log(path)
        if(path === '/achievements' || path === '/partners' || path === '/strategy') {
            path = '/about-us';
        }

        if(path === '/en/achievements' || path === '/en/partners' || path === '/en/strategy') {
            path = '/en/about-us';
        }

        const $active_links = $links.filter(function(index, link) {
            return link.getAttribute('href') === path;
        });

        $active_links.addClass('_active');
    }
}