export default class ProductsTypesList {

    getProductsTypes(is_main = false) {
        let it = this;

        return new Promise((resolve, reject) => {
            $.ajax({
                headers: {
                    'X-localization': SITE_LANGUAGE.toLowerCase(),
                },
                url: `/api/product-types${is_main ? '?show_main=1' : ''}`,
                method: 'GET',
                complete: (data) => {
                    if (data.status === 200) {
                        it.products_types = data.responseJSON.data;
                        resolve(data.responseJSON)
                    }
                }
            })
        })
    }


    getProductsTypesCategories() {
        let it = this;

        return new Promise((resolve, reject) => {
            $.ajax({
                headers: {
                    'X-localization': SITE_LANGUAGE.toLowerCase(),
                },
                url: `/api/product-type-categories`,
                method: 'GET',
                complete: (data) => {
                    if (data.status === 200) {
                        resolve(data.responseJSON)
                    }
                }
            })
        })
    }


    getProductTypeById(products_type_id) {
        let it = this;

        if (!products_type_id.length) {
            return it.products_types;
        } else {
            return it.products_types.filter((products_type) => {
                return products_type.id == products_type_id;
            })
        }
    }


    createCardHtml(card) {
        const is_active = card.attributes.currency ? true : false;

        let language_string = '';
        let agreement_string = 'по согласованию';

        if(SITE_LANGUAGE === 'EN') {
            language_string = '/en';
            agreement_string = 'by agreement';
        }

        return `
        <div class="product-card ${!is_active ? '_disabled' : ''}" data-filter=${card.attributes.category_slug}>
            ${is_active ? `<a class="product-card__link" href="${language_string}/product?id=${card.id}"></a>` : ''}
            <div class="product-card__image">
            <img src="/storage/${card.attributes.preview}" alt=${card.attributes.title}></div>
            <div class="product-card__info">
                <h5>${card.attributes.title}</h5>
                <div class="product-card__text">${!is_active ? agreement_string : card.attributes.preview_text}</div>
            </div>
        </div>
        `
    }
}