export default class IndustrySubscriptionForm {

    constructor() {
        let it = this;

        it.$form = $('#industry-subscription-form');
        it.$submit_btn = it.$form.find('button[type="submit"]');

        it.error_messages_ru = {
          email: { 
            required: "Введите E-mail",
            email: "Некорректный E-mail",
          }
        };

        it.error_messages_en = {
          email: { 
            required: "Enter E-mail",
            email: "Invalid E-mail",
          }
        };

        switch(SITE_LANGUAGE) {
          case('RU'):
            it.error_messages = it.error_messages_ru;
            break;
          case('EN'):
            it.error_messages = it.error_messages_en;
            break;
          default: it.error_messages = it.error_messages_ru;
        }

        it.initForm();
    }


    initForm() {
        let it = this;

        it.$form[0].reset();

        let validator = it.$form.validate({
            errorElement: "span",
            focusInvalid: false,
            highlight: function(element) {
              $(element).addClass("error").removeClass("valid");
              it.$submit_btn.addClass('disabled');
            },
            unhighlight: function(element) {
              $(element).removeClass("error").addClass("valid");
            },
            submitHandler: function() {
              it.handleFormSubmit();
            },
            rules: {
              "email": {required: true, email: true},
            },
            messages: it.error_messages
        });
    }

    
    handleFormSubmit() {
        let it = this;

        it.$submit_btn.attr('disabled', true);

        $.ajax({
          url: it.$form.attr('action'),
          method: it.$form.attr('method'),
          processData: false,
          contentType: false,
          data: new FormData(it.$form[0]),
          complete: (data) => {
            it.$submit_btn.removeAttr('disabled');
            if(data.status === 201) {
              it.$submit_btn.removeAttr('disabled');
              it.$form.addClass('_has-success');
            } else {
              switch(SITE_LANGUAGE) {
                case('RU'):
                  alert('Произошла ошибка. Попробуйте перезагрузить страницу');
                  break;
                case('EN'):
                  alert('An error has occured. Please try to reload page');
                  break;
                default: alert('Произошла ошибка. Попробуйте перезагрузить страницу');
              }
            }
          }
        })
    }
}