export default class Contacts {

    constructor() {
        let it = this;

        it.map_container = document.querySelector('.contacts__map-body');
        it.$items = $('.contacts__item');
        
        it.initMap();
        it.getContacts();
    }

    initMap() {
        let it = this;
    
        if (typeof google === 'object' && typeof google.maps === 'object') {
            it.map = new google.maps.Map(it.map_container, {
                center: {lat: 47.21698490179079, lng: 39.7226689662814}, // Координаты Офиса в Ростове
                zoom: 12
            });
            it.createMarkers();
             // Добавляем задержку перед попыткой закрыть возможное уведомление об ошибке
            setTimeout(() => {
                const dismissButton = document.querySelector('.dismissButton');
                if (dismissButton) {
                    dismissButton.click(); // Нажать кнопку отказа от уведомления

                    // Дополнительная задержка перед активацией маркера Ростова
                    setTimeout(() => {
                        it.activateFirstItem();
                    }, 100); // 0,1 секунды после закрытия уведомления
                }
            }, 400); // 0,4 секунды перед попыткой закрыть уведомление
        } else {
            setTimeout(() => {
                it.initMap();
            }, 500);
        }
    }


    createMarkers() {
        let it = this;

        const image = new google.maps.MarkerImage(
            "../img/icons/map-marker.png",
            new google.maps.Size(28,28),
            new google.maps.Point(0,0)
        );
    
        it.bounds = new google.maps.LatLngBounds(); 

        it.$items.each(function(index, item) {
            const lat = +item.getAttribute('data-lat'),
                lng = +item.getAttribute('data-lng'),
                coords = {lat: lat, lng: lng};

            let marker = new google.maps.Marker({
                position: coords,
                map: it.map,
                icon: image
            });

            marker.addListener('click', function() {
                it.activateMarker(item, coords);
            });

            item.addEventListener('click', function(event) {
                it.activateMarker(item, coords, event);
            });

            it.bounds.extend(coords);

            if (lat === 47.21698490179079 && lng === 39.7226689662814) { // Если нужно сразу активировать Ростов-на-Дону
                it.activateMarker(item, coords);
            }
        });

        it.map.fitBounds(it.bounds);
        it.map.panToBounds(it.bounds); 
    }

    // Костыль чтобы не работать с Google API
    activateFirstItem() {
        let it = this;
        let firstItem = $('.contacts__item[data-lat="47.21698490179079"][data-lng="39.7226689662814"]'); // выбирается элемент Ростовского офиса
        if (firstItem.length) {
            let lat = +firstItem.attr('data-lat'),
                lng = +firstItem.attr('data-lng'),
                coords = {lat: lat, lng: lng};
            
            it.activateMarker(firstItem.get(0), coords); // Активация маркера
        }
    }


    activateMarker(item, coords, event) {
        let it = this;
    
        if(event && event.target.closest('a')) return;
    
        if(item.classList.contains('_active')) {
            item.classList.remove('_active');
            it.map.fitBounds(it.bounds);
            it.map.panToBounds(it.bounds); 
        } else {
            it.map.panTo(coords);
            it.map.setZoom(18);
    
            it.$items.removeClass('_active');
            item.classList.add('_active');
        }
    }


    getContacts() {
        let it = this;

        $.ajax({
            headers: {
                'X-localization': SITE_LANGUAGE.toLowerCase(),
            },
            url: '/api/contacts',
            method: 'GET',
            complete: (data) => {
                if(data.responseJSON.data) {
                    data.responseJSON.data.forEach((item) => {
                        $('.contacts__departments-inner').append(it.createContactHtml(item))
                    })
                }
            }
        })
    }


    createContactHtml(item) {
        return `
        <div class="contacts__department">
            <h5>${item.attributes.title}</h5>
            <a href="tel:${item.attributes.phone}" class="contacts__department-phone text">${item.attributes.phone}</a>
            <br/>
            <a href="mailto:${item.attributes.email}" class="contacts__department-mail text">${item.attributes.email}</a>
        </div>
        `
    }
}



